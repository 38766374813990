import React, { useState } from "react";
import { login, recoverPassword, register } from "../api/auth";
import LoginForm from "../components/auth/LoginForm";
import SignUpForm from "../components/auth/SignUpForm";
import ForgotPasswordForm from "../components/auth/ForgotPasswordForm";
import ModalMessage from "../components/auth/ModalMessage";
import ForgotPasswordFormSuccess from "../components/auth/ForgotPasswordFormSuccess";
import SignUpFormSuccess from "../components/auth/SignUpFormSuccess";
import { getUserApi } from "../slices/userSlice"; 

import { useDispatch } from "react-redux";
import useLoginPageHook from "./hooks/useLoginPageHook";
/**
 * Represents the login page component.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.setAuthObject - The function to set the authentication object.
 * @returns {JSX.Element} The LoginPage component.
 */
const LoginPage = ({ setAuthObject }) => {


  const dispatch = useDispatch();
  const [form, setForm] = useState("LOGIN");
  const [message, setMessage] = useState("");

  useLoginPageHook(setAuthObject);

  /**
   * Submits the login form.
   *
   * @param {Event} event - The form submit event.
   * @returns {Promise<void>} A promise that resolves when the login process is completed.
   */
  const submitLogin = async (event) => {
    event.preventDefault();
    var data = new FormData(event.target);
    console.log("login", data);
    setMessage("LOADING");

    try {
      const response = await login(data);
      console.log(response);

      if (response && response.data && response.data.data) {
        localStorage.setItem("auth", JSON.stringify(response.data.data));

        dispatch(getUserApi());
      }
    } catch (err) {
      if (err.response.data) {
        if (err.response.data.error === "UNAUTHORIZED") {
          setMessage("UNAUTHORIZED");
        } else if (err.response.data.error === "CONFIRMATION_PENDING") {
          setMessage("CONFIRMATION_PENDING");
        }
      } else {
        setMessage("UNDEFINED_ERROR");
      }
    }
  };

  /**
   * Submits the sign up form.
   *
   * @param {Event} event - The form submit event.
   * @returns {Promise<void>} A promise that resolves when the sign up process is completed.
   */
  const submitSignUp = async (event) => {
    event.preventDefault();
    var data = new FormData(event.target);

    if (data.get("password") !== data.get("confirm_password")) {
      setMessage("CONFIRM_PASSWORD_ERROR");
    } else {
      try {
       await register(data);
        setForm("SIGNUPSUCCESS");
        setMessage("");
      } catch (err) {
        if (err.response.data && err.response.data.error === "EMAIL_EXIST") {
          setMessage("EMAIL_EXIST");
        } else {
          setMessage("UNDEFINED_ERROR");
        }
      }
    }
  };
  /**
   * Submits the restore password form.
   *
   * @param {Event} event - The form submit event.
   * @returns {Promise<void>} A promise that resolves when the restore password process is completed.
   */
  const submitRestorePassword = async (event) => {
    setMessage("LOADING");
    event.preventDefault();
    var data = new FormData(event.target);
    console.log("submitRestorePassword", data);

    try {
      const response = await recoverPassword(data);
      console.log("submitRestorePassword", response.data.data);
      setForm("FORGOTPASSWORDSUCCESS");
      setMessage("");
    } catch (err) {
      if (err.response.data && err.response.data.error === "EMAIL_NOT_FOUND") {
        setMessage("EMAIL_NOT_FOUND");
      } else {
        setMessage("UNDEFINED_ERROR");
      }
    }
  };
  /**
   * Closes the modal and performs specific actions based on the current message state.
   *
   * @param {Event} event - The click event.
   * @returns {void}
   */
  const closeModal = async (event) => {
    event.preventDefault();

    if (message === "LOADING") {
      return;
    }
    if (message === "CONFIRM_PASSWORD_ERROR") {
      document.getElementById("confirm_password").select();
    } else if (message === "UNAUTHORIZED") {
      document.getElementById("email").select();
    } else if (message === "EMAIL_NOT_FOUND") {
      document.getElementById("email").select();
    }
    setMessage("");
  };

  return (
    <div className="basic-container d-flex align-items-center justify-content-center flex-column flex-grow-1">
      <img className="logo" src="/imgs/logo.svg" alt="Where" />
      {form === "LOGIN" && (
        <LoginForm
          submitLogin={submitLogin}
          setForm={setForm}
          message={message}
        />
      )}
      {form === "SIGNUP" && (
        <SignUpForm
          submitSignUp={submitSignUp}
          setForm={setForm}
          message={message}
        />
      )}
      {form === "SIGNUPSUCCESS" && <SignUpFormSuccess setForm={setForm} />}
      {form === "FORGOTPASSWORD" && (
        <ForgotPasswordForm
          submitRestorePassword={submitRestorePassword}
          setForm={setForm}
          message={message}
        />
      )}
      {form === "FORGOTPASSWORDSUCCESS" && (
        <ForgotPasswordFormSuccess setForm={setForm} />
      )}
      {message && <ModalMessage message={message} closeModal={closeModal} />}
    </div>
  );
};

export default LoginPage;
