import { useEffect } from "react";
import { getFeatures } from "../utils/Util";
import VectorLayer from "ol/layer/Vector";
import {  useSelector } from "react-redux";

const useLoadMarkersHook = (
  mapOL,
  setShowSearch,
  currentLocationMarkerRef
) => {
  
  const data = useSelector((state) => state.markers.data);
  useEffect(() => {
    // This code block will be executed whenever the 'data' dependency changes

    console.log("data", data);

    // Check if 'mapOL.current' is not null
    if (mapOL.current) {
      console.log("--------------> ON MARKERS REFRESH");

      // Call 'getFeatures' function with 'data' as a parameter to get the features
      const features = getFeatures(data);

      // Refresh Features
      const vectorLayer = mapOL.current
        .getLayers()
        .getArray()
        .find((layer) => layer instanceof VectorLayer);
      if (vectorLayer) {
        const vectorSource = vectorLayer.getSource();
        vectorSource.clear();
        vectorSource.addFeatures(features);

        if (currentLocationMarkerRef.current) {
          vectorLayer.getSource().addFeature(currentLocationMarkerRef.current);
        }
      }

      setShowSearch(false);
    }
    return () => {};
  }, [data, mapOL, setShowSearch, currentLocationMarkerRef]);
};
export default useLoadMarkersHook;
