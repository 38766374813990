import { useEffect } from "react";

/**
 * Custom hook for getting user data and check auth.
 *
 */
const useBasicTemplateConnectionHook = (setConnected) => {


  useEffect(() => {
    const handleOffline = () => {
      console.log("Connection lost");
      setConnected(false);
    };

    const handleOnline = () => {
      console.log("Connection restored");
      setConnected(true);
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, [setConnected]);
};
export default useBasicTemplateConnectionHook;
