import API from "./api";


export const login = (formData) => API.post("login", formData);
export const register = (formData) => API.post("register", formData);
export const recoverPassword = (formData) => API.post("recover-password", formData);
export const changePassword = (formData) => API.post("change-password", formData);
export const sendConfirmationMail = (formData) => API.post("send-confirmation-mail", formData);
export const updateAccountName = (formData) => API.post("account-update-name", formData);
export const checkAuth = () => API.post("check-auth");


