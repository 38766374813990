import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from './locales/en/translation.json';
import translationES from './locales/es/translation.json';
import translationCA from './locales/ca/translation.json';
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  },
  ca: {
    translation: translationCA
  }
};

i18n
.use(LanguageDetector) // Usa el detector de idioma del navegador
.use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en", // Idioma predeterminado
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: true
    }
  });

  export default i18n;