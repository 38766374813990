import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getMarkersByCoordinates } from "../api/markers";


export const getMarkers = createAsyncThunk(
  "markers/getMarkers",
  async ({ latitude, longitude }) => {
    const response = await getMarkersByCoordinates(latitude, longitude);
    return response.data;
  }
);

export const mapSlice = createSlice({
  name: "markers",
  initialState: {
    data: [],
    loading: "idle",
    error: null,
    coordinates: null,
    currentCoordinates: null
  },
  reducers: {
    setCoordinates: (state, action) => { 
      state.coordinates = action.payload;
    },
    setCurrentCoordinates: (state, action) => { 
      state.currentCoordinates = action.payload;
    },
    setError: (state, action) => { 
      state.error = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getMarkers.pending, (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
        state.error = null;
      }
    });

    builder.addCase(getMarkers.fulfilled, (state, action) => {
      if (state.loading === "pending") {
        state.data = action.payload.features;
        state.loading = "idle";
        state.error = null;
      }
    });

    builder.addCase(getMarkers.rejected, (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.error = "Error occured";
      }
    });
  },
});
export const { setCoordinates, setCurrentCoordinates, setError } = mapSlice.actions;

export default mapSlice.reducer;
