import React, { useState } from "react";
import { useSelector } from "react-redux";
import { fromLonLat } from "ol/proj";
import { settings } from "../../Config";

/**
 * Renders a map page with markers for nearby places.
 * @returns {JSX.Element} The map page component.
 */

const MapCurrentLocationButton = ({ mapOL, followGeolocationRef }) => {
  const [disabledClick, setDisabledClick] = useState(false);
  const currentCoordinates = useSelector(
    (state) => state.markers.currentCoordinates
  );

  // Handle current location button click event
  const handleCurrentLocationButton = async (event) => {
    event.stopPropagation();
    console.log("handleCurrentLocationButton");

    setDisabledClick(true);

    if (currentCoordinates) {
      const center = fromLonLat([
        currentCoordinates.longitude,
        currentCoordinates.latitude,
      ]);
      followGeolocationRef.current = true;
      mapOL.current.getView().setCenter(center);
      mapOL.current.getView().setZoom(settings.zoomDefault);

    }

    setDisabledClick(false);
  };
  if (!disabledClick) {
    return (
      <div
        className="current-location-button"
        onClick={handleCurrentLocationButton}
      >
        <img src="imgs/icons/location.svg" alt="Current Location" />
      </div>
    );
  } else {
    return null;
  }
};
export default MapCurrentLocationButton;
