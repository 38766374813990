import { useEffect } from "react";
import { getMarkerContent } from "../../../api/markers";
import { useTranslation } from "react-i18next";
import { fetchWikipediaExtract } from "../../../services/WikipediaService";
import {
  getMarkerContentFromCache,
  saveMarkerContent,
  storeMarkerContentInCache,
  translateContent,
} from "../utils/Util";
import he from "he";

/**
 * Custom hook for setting coordinates.
 *
 * @param {Object} feature - The feature object.
 * @param {Function} dispatch - The dispatch function.
 * @param {Object} geolocation - The geolocation object.
 * @param {Object} currentCoordinates - The current coordinates object.
 * @param {Object} mapOL - The mapOL object.
 * @param {string} display - The display setting.
 * @param {Object} currentLocationMarkerRef - The current location marker reference.
 * @param {Object} followGeolocationRef - The follow geolocation reference.
 */
const useMarkerInfoModalHook = (feature, contentCache, setContent, setSelectedFeature) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    // This code block will be executed whenever the 'data' dependency changes
    if (feature == null) {
      setSelectedFeature(null);
      return;
    }

    // Fetches content from the API
    const fetchContent = async () => {
      const response = await getMarkerContent(
        feature.get("id"),
        i18n.language.substring(0, 2)
      );

      if (response.error) {
        return response;
      }

      if (response.data.status === 1) {
        return response.data;
      } else {
        return null;
      }
    };

    // Process the API content
    const processApiContent = (content) => {
      if (content && content.error) {
        console.log("error", content.error);
        setContent(content);
        return;
      }
      // if no content is found in the Api DB, fetch it from wikipedia
      if (content === null) {
        // Fetches content from wikipedia
        fetchWikipediaExtract(feature)
          .then((data) => {
            // process wikipedia data
            processWikiData(data);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        // Store the content in the local cache
        storeMarkerContentInCache(
          {
            id: feature.get("id"),
            language_code: i18n.language.substring(0, 2),
            title: content.data.title,
            text: content.data.text,
          },
          contentCache
        );
        setContent({ title: content.data.title, text: content.data.text });
      }
    };

    const processWikiData = async (data) => {
      console.log("processWikiData", data);
      // Check if the response contains the content
      if (data && data.query && data.query.pages) {
        const pages = data.query.pages;
        const page = pages[Object.keys(pages)[0]];

        if (
          feature.get("article_language_code") === i18n.language.substring(0, 2)
        ) {
          console.log("saveMarkerContent", data);
          saveMarkerContent(
            feature,
            i18n,
            contentCache,
            page.title,
            page.extract
          );

          setContent({
            title: he.decode(page.title),
            text: he.decode(page.extract),
          });
        } else {
          translateContent(page, feature, i18n, contentCache).then((data) =>
            setContent({
              title: he.decode(data.title),
              text: he.decode(data.extract),
            })
          );
        }
      }
    };

    const marker = getMarkerContentFromCache(feature, contentCache, i18n);

    if (marker !== null) {
      setContent({ title: marker.title, text: marker.text });
    } else {
      // Fetches content from the API
      fetchContent().then((content) => {
        console.log("fetchContent", content);

        processApiContent(content);
      });
    }
  }, [setContent, feature, i18n, contentCache, setSelectedFeature]);
};
export default useMarkerInfoModalHook;
