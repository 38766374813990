import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { updateUser } from "../api/user";
import { checkAuth } from "../api/auth";

export const getUserApi = createAsyncThunk("user/getUserApi", async () => {
  const response = await checkAuth();
  console.log(response.data);
  return response.data;
});
export const updateUserApi = createAsyncThunk("user/updateUserApi", async (formData) => {
  const response = await updateUser(formData);
  return response.data;
});

export const userSlice = createSlice({
  name: "user",
  initialState: {
    data: null,
    loading: 'idle',
    error: null
  },
  reducers: {
    setUser: (state, action) => {
      state.data = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserApi.pending, (state, action) => {
      console.log("pending", state.loading);
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    });

    builder.addCase(getUserApi.fulfilled, (state, action) => {
      console.log("fulfilled", state.loading);
      if (state.loading === "pending") {
        console.log("action.payload", action.payload.data);
        state.data = action.payload.data;
        state.loading = "idle";
        state.error = null;
      }
    });

    builder.addCase(getUserApi.rejected, (state, action) => {
      if (state.loading === "pending") {
        console.log(" action.payload",  action.payload); 
        state.loading = "idle";
        state.error = "Error occured";
      }
    });

    
    builder.addCase(updateUserApi.pending, (state, action) => {
      if (state.loading === "idle") {
        state.loading = "pending";
      }
    });

    builder.addCase(updateUserApi.fulfilled, (state, action) => {
      if (state.loading === "pending") {
        state.data = {...state.data, ...action.payload.data.user};
        state.loading = "idle";
        state.error = null;
      }
    });

    builder.addCase(updateUserApi.rejected, (state, action) => {
      if (state.loading === "pending") {
        state.loading = "idle";
        state.error = "Error occured";
      }
    });
  }
});
export const { setUser } = userSlice.actions;

export default userSlice.reducer;
