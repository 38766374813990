import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getUserApi } from "../../../../slices/userSlice";

/**
 * Custom hook for getting user data and check auth.
 *
 */
const useBasicTemplateUserHook = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.data);

  useEffect(() => {
    console.log("BasicTemplate user", user);

    // Check if user data is already loaded
    if (user === null) {
      // If its not loaded, check if the user is authenticated

      console.log("getUserApi");
      dispatch(getUserApi());
    }
  }, [user, navigate, dispatch]);
};
export default useBasicTemplateUserHook;
