import { useEffect } from "react";

/**
 * Custom hook for setting coordinates.
 *
 * @param {Object} feature - The feature object.
 * @param {Function} setImageLoaded - The setImageLoaded state function.
 */
const useMarkerInfoModalImagelHook = (feature, setImageLoaded) => {
  useEffect(() => {
    // This code block will be executed whenever the 'data' dependency changes
    if (feature && !feature.get("image")) {
      setImageLoaded(true);
    }
  }, [feature, setImageLoaded]);
};
export default useMarkerInfoModalImagelHook;
