import React from "react";

import { useDispatch } from "react-redux";
import { setCoordinates } from "../../slices/mapSlice";
import { toLonLat } from "ol/proj";
import { useTranslation } from "react-i18next";

/**
 * Renders a map page with markers for nearby places.
 * @returns {JSX.Element} The map page component.
 */

const MarkersSearchFloatingButton = ({ map, loading }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  // Handle floating top button click event
  const handleFloatingTopButton = (event) => {
    event.stopPropagation();
    const center = map.current.getView().getCenter();
    const lonLat = toLonLat(center);

    dispatch(setCoordinates({ latitude: lonLat[1], longitude: lonLat[0] }));
  };
  return (
    <div
      className={`floating-top-button position-absolute text-light ${
        loading === "pending" ? "bg-secondary" : ""
      }`}
      onClick={handleFloatingTopButton}
    >
      {loading === "pending" && (
        <span
          className="spinner-grow spinner-grow-sm"
          role="status"
          aria-hidden="true"
        ></span>
      )}

      <span className="pl-1"> {t("SEARCH_IN_THIS_AREA")}</span>
    </div>
  );
};

export default MarkersSearchFloatingButton;
