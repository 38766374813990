import React, {  useState } from "react";
import BasicTemplate from "../components/templates/basic/BasicTemplate";
import useHelpPageHook from "./hooks/useHelpPageHook";
/**
 * Renders a map page with markers for nearby places.
 * @returns {JSX.Element} The map page component.
 */

const HelpPage = () => {

  const [loadedHTML, setLoadedHTML] = useState(null); 
  useHelpPageHook(setLoadedHTML);

  return (
    <BasicTemplate>
      <div className="content px-3">
        {loadedHTML && (
          <>
            <h1 className="my-4">Ayuda</h1>

            <div
              className="loaded-html-container mb-3"
              dangerouslySetInnerHTML={{
                __html: loadedHTML,
              }}
            />
          </>
        )}
      </div>
    </BasicTemplate>
  );
};

export default HelpPage;
