import API from "./api";

/**
 * Retrieves markers based on latitude and longitude.
 * @param {number} latitude - The latitude coordinate.
 * @param {number} longitude - The longitude coordinate.
 * @returns {Promise} - A promise that resolves to the markers data.
 */
export const getMarkersByCoordinates = async (latitude, longitude) => {
  try {
    const response = await API.get(
      `${process.env.REACT_APP_API_URL}markers/get/es/${latitude}/${longitude}`
    );
    return response;
  } catch (error) {
    // Handle the network error here
    console.log("Network Error:", error);
  }
};

/**
 * Retrieves the content of a marker based on its ID and language code.
 * @param {string} marker_id - The ID of the marker.
 * @param {string} language_code - The language code.
 * @returns {Promise} - A promise that resolves to the marker content.
 */

export const getMarkerContent = async (marker_id, language_code) => {
  try {
    const response = await API.get(
      `markers/articles-content/get/${marker_id}/${language_code}`
    );
    return response;
  } catch (error) {
    // Handle the network error here
    console.log("Network Error:", error);
    return { error: error.message };
  }
};
/**
 * Adds a title to the content of a marker.
 * @param {string} marker_id - The ID of the marker.
 * @param {string} language_code - The language code.
 * @param {string} title - The title to be added.
 * @param {string} text - The text to be added.
 * @returns {Promise} - A promise that resolves when the title is added.
 */
export const addMarkerContent = async (marker_id, language_code, data) => {
  try {
    const response = await API.post(
      `markers/articles-content/add/${marker_id}/${language_code}`,
      data
    );
    return response;
  } catch (error) {
    // Handle the network error here
    console.error("Network Error:", error);
    throw error;
  }
};

/**
 * Retrieves the HTML content of a wikipedia article based on its title and language code.
 * @param {string} title - The title of the article.
 * @param {string} language_code - The language code.
 * @returns {Promise} - A promise that resolves to the marker content.
 */

export const getArticleFromWikipedia = (title, language_code) =>
  API.get(`markers/articles/get/${title}/${language_code}`);
