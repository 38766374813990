import React, { useRef, useState } from "react";

import { useTranslation } from "react-i18next";

import ArticleViewer from "../article/ArticleViewer";
import useMarkerInfoModalHook from "./hooks/useMarkerInfoModalHook";
import useMarkerInfoModalImagelHook from "./hooks/useMarkerInfoModalImagelHook";

/**
 * Renders a map page with markers for nearby places.
 * @returns {JSX.Element} The map page component.
 */

const MarkerInfoModal = ({ feature, setSelectedFeature }) => {
  const { t } = useTranslation();

  const [showArticle, setShowArticle] = useState(false);
  const [content, setContent] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const contentCache = useRef([]);
  const popupElement = useRef(null);
  const popupTitleElement = useRef(null);
  const popupContentElement = useRef(null);

  // Call the custom hook
  useMarkerInfoModalHook(feature, contentCache, setContent, setSelectedFeature);

  useMarkerInfoModalImagelHook(feature, setImageLoaded);


  // Close popup
  const closePopup = (event) => {
    event.stopPropagation();
    console.log("close popup");
    if (feature) {
      setSelectedFeature(null);
    }
  };

  return (
    <>
      <div
        ref={popupElement}
        className="ol-popup position-absolute top-0 start-0 overflow-hidden d-flex justify-content-center align-items-center w-100 h-100"
        onClick={closePopup}
      >
        <div
          className="layout bg-light border-dark overflow-hidden d-flex flex-column justify-content-start align-items-center overflow-hidden"
          onClick={(event) => {
            console.log("layout clicked");
            event.stopPropagation();
          }}
        >
          <div className="close text-end w-100">
            <span onClick={closePopup}>{t("CLOSE")}</span>
          </div>

          <div
            ref={popupTitleElement}
            className="title px-3 fw-bold text-uppercase"
          >
            {feature && feature.get("article_title")
              ? feature.get("article_title")
              : ""}
          </div>

          <div className="content-container px-3">
            {feature && feature.get("image") && (
              <img
                alt=""
                onLoad={() => {
                  console.log("image loaded");
                  setImageLoaded(true);
                }}
                src={
                  feature && feature.get("image") ? feature.get("image") : ""
                }
              />
            )}

            {content && content.text && imageLoaded && (
              <div ref={popupContentElement} className="content  mt-2">
                {content.text}
              </div>
            )}

            {content && content.error && (
              <div className="content  my-3 text-center text-danger">
                {t("ERROR_LOADING_CONTENT")}
              </div>
            )}

            {content && content.text && imageLoaded && (
              <div className="my-3 text-center text-primary">
                <button
                  className="btn btn-info"
                  onClick={() => setShowArticle(true)}
                >
                  {t("LEARN_MORE")}
                </button>
              </div>
            )}

            {(!content || !content.text) && !imageLoaded && (
              <div className="spinner mx-auto my-3"></div>
            )}
          </div>
        </div>
      </div>
      {showArticle && (
        <ArticleViewer feature={feature} setShowArticle={setShowArticle} />
      )}
    </>
  );
};

export default MarkerInfoModal;
