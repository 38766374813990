import { useEffect } from "react";
import {
  setCoordinates,
} from "../../../slices/mapSlice";
import VectorLayer from "ol/layer/Vector";

import { fromLonLat } from "ol/proj";
import Feature from "ol/Feature";
import Point from "ol/geom/Point";
import { Icon, Style } from "ol/style";
import { settings } from "../../../Config";
import { useDispatch, useSelector } from "react-redux";

/**
 * Custom hook for setting coordinates.
 *
 * @param {Object} mapOL - The mapOL object.
 * @param {string} display - The display setting.
 * @param {Object} currentLocationMarkerRef - The current location marker reference.
 * @param {Object} followGeolocationRef - The follow geolocation reference.
 */
const useSetCoordinatesHook = (
  mapOL,
  display,
  currentLocationMarkerRef,
  followGeolocationRef
) => {


  const dispatch = useDispatch();
  const coordinates = useSelector((state) => state.markers.coordinates);
  const currentCoordinates = useSelector((state) => state.markers.currentCoordinates);
  useEffect(() => {
    // This code block will be executed whenever the 'coordinates' or 'dispatch' dependencies change
    console.log("--------------> ON COORDINATES CHANGE");
    if (coordinates === null && currentCoordinates) {
      dispatch(setCoordinates(currentCoordinates));
    } else if (mapOL.current && currentCoordinates) {
      const center = fromLonLat([
        currentCoordinates.longitude,
        currentCoordinates.latitude,
      ]);
      console.log("currentCoordinates", currentCoordinates);
      if (!currentLocationMarkerRef.current) {
        // Create a new feature with an icon
        currentLocationMarkerRef.current = new Feature({
          geometry: new Point(center),
        });

        const iconStyle = new Style({
          image: new Icon({
            src: settings.currentLocationMarker.src,
            scale:
              display === settings.breakpoints.ONLY_ICONS_SMALL
                ? settings.currentLocationMarker.scaleSmall
                : settings.currentLocationMarker.scaleBig,
          }),
        });

        currentLocationMarkerRef.current.setStyle(iconStyle);

        // Add the feature to the mapOL

        const vectorLayer = mapOL.current
          .getLayers()
          .getArray()
          .find((layer) => layer instanceof VectorLayer);
        vectorLayer.getSource().addFeature(currentLocationMarkerRef.current);
      } else {
  
        currentLocationMarkerRef.current.getGeometry().setCoordinates(center);

        if (currentCoordinates.heading) {
          const iconStyle = new Style({
            image: new Icon({
              src: settings.currentLocationMarker.src,
              scale:
                display === settings.breakpoints.ONLY_ICONS_SMALL
                  ? settings.currentLocationMarker.scaleSmall
                  : settings.currentLocationMarker.scaleBig,
              rotation: currentCoordinates.heading,
            }),
          });

          currentLocationMarkerRef.current.setStyle(iconStyle);
        }
      }
      if (followGeolocationRef.current) {
        mapOL.current.getView().setCenter(center);
      }
    }
  }, [
    currentCoordinates,
    coordinates,
    dispatch,
    mapOL,
    display,
    currentLocationMarkerRef,
    followGeolocationRef
  ]);

};
export default useSetCoordinatesHook;
