import React from "react";
import BasicTemplate from "../components/templates/basic/BasicTemplate";
import Map from "../components/map/Map";
/**
 * Renders a map page with markers for nearby places.
 * @returns {JSX.Element} The map page component.
 */

const MapPage = () => {
  // pull refs


  return (
    <BasicTemplate>
      <div className="position-relative flex-grow-1 d-flex flex-column justify-content-start align-items-start my-0 mx-auto w-100">
        <Map />
      </div>
    </BasicTemplate>
  );
};

export default MapPage;
