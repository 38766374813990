import React, { useState } from "react";
import { changePassword } from "../api/auth";
import { useTranslation } from "react-i18next";

import { Link, useNavigate, useParams } from "react-router-dom";
import { settings } from "../Config";
/**
 * Represents a page component for restoring a password.
 *
 * @param {Object} props - The component props.
 * @returns {JSX.Element} The JSX element representing the RestorePasswordPage.
 */
const RestorePasswordPage = () => {
  const navigate = useNavigate();
  const { email, code } = useParams();

  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(0);

  const { t } = useTranslation();
  const submit = async (event) => {
    event.preventDefault();
    var data = new FormData(event.target);
    if (data.get("password") !== data.get("confirm_password")) {
      setMessage("CONFIRM_PASSWORD_ERROR");
    } else {
      try {
        await changePassword(data);
        setMessage("");
        setStatus(1);
      } catch (err) {
        if (err.response.data) {
          if (err.response.data.error === "PASSWORD_INVALID_CODE") {
            setMessage("PASSWORD_INVALID_CODE");
          } else if (err.response.data.error === "VALIDATION_ERROR")
            setMessage("VALIDATION_ERROR");
        } else {
          setMessage("UNDEFINED_ERROR");
        }
      }
    }
  };
  /**
   * Closes the modal and performs specific actions based on the current message state.
   *
   * @param {Event} event - The click event.
   * @returns {void}
   */
  const closeModal = async (event) => {
    event.preventDefault();

    if (message === "LOADING") {
      return;
    }
    if (message === "CONFIRM_PASSWORD_ERROR") {
      document.getElementById("confirm_password").select();
    } else {
      navigate("/");
    }
    setMessage("");
  };
  return (
    <div className="basic-container d-flex align-items-center justify-content-center flex-column flex-grow-1">
      <img className="logo" src="/imgs/logo.svg" alt="Where" />

      <div className="content p-4 rounded-4">
        {status === 0 && (
          <form onSubmit={submit} autoComplete="off">
            <input type="hidden" name="email" value={email} />
            <input type="hidden" name="code" value={code} />
            <label className="w-100 mt-2 text-light" htmlFor="password">
              {t("PASSWORD")}
            </label>
            <input
              type="password"
              required={true}
              className="form-control w-100"
              name="password"
              id="password"
              minLength={settings.auth.passwordMinLength}
            />
            <label className="w-100 mt-2 text-light" htmlFor="confirm_password">
              {t("CONFIRM_PASSWORD")}
            </label>
            <input
              type="password"
              required={true}
              className="form-control w-100"
              name="confirm_password"
              id="confirm_password"
              minLength={settings.auth.passwordMinLength}
            />

            {message !== "LOADING" && (
              <button type="submit" className="w-100 btn btn-info mt-3">
                {t("CHANGE_PASSWORD")}
              </button>
            )}

            {message === "LOADING" && (
              <button
                className="w-100 btn btn-info mt-3"
                type="button"
                disabled
              >
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="sr-only ps-1">{t("LOADING")}...</span>
              </button>
            )}
          </form>
        )}
        {status === 1 && (
          <>
            <p className="text-light fs-4">{t("PASSWORD_CHANGED")}</p>
            <Link className="w-100 btn btn-info btn-lg mt-3" to="/">
              {t("LOGIN")}
            </Link>
          </>
        )}
      </div>
      {message && (
        <div className="login-modal d-flex flex-column justify-content-start align-items-center w-100 h-100" onClick={closeModal}>
          <p className="error-message text-center w-75 p-3 mt-5 bg-dark text-light">{t(message)}</p>
        </div>
      )}
    </div>
  );
};

export default RestorePasswordPage;
