import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { settings } from "../../Config";
import { useSelector, useDispatch } from "react-redux";
import {  updateUserApi } from "../../slices/userSlice";

/**
 * Renders a map page with markers for nearby places.
 * @returns {JSX.Element} The map page component.
 */

const ProfileUpdate = ({ setMessage }) => {
  const user = useSelector((state) => state.user.data);
  const loading = useSelector((state) => state.user.loading);
  const { t } = useTranslation();

  const [currentUser, setCurrentUser] = useState(user);
  const dispatch = useDispatch();
  const update = async (event) => {
    event.preventDefault();
    var data = new FormData(event.target);

    try {
      console.log("loading", loading);

      dispatch(updateUserApi(data));

    } catch (err) {
      console.log(err);
      if (err.response && err.response.data) {
        if (err.response.data.error === "EMAIL_ALREADY_EXISTS") {
          setMessage("EMAIL_ALREADY_EXISTS");
        } else if (err.response.data.error === "VALIDATION_ERROR")
          setMessage("VALIDATION_ERROR");
      } else {
        setMessage("UNDEFINED_ERROR");
      }
    }
  };

  return (
    <>
      {user && (
        <form autoComplete="off" onSubmit={update}>
          <div className="row">
            <div className="col-md-3 col-lg-2">
              <label htmlFor="name" className="form-label fw-bold">
                {t("NAME")}
              </label>
            </div>
            <div className="col-md-9 col-lg-10">
              <input
                type="text"
                className="form-control"
                value={currentUser.name}
                id="name"
                name="name"
                required
                minLength={settings.auth.nameMinLength}
                maxLength={settings.auth.nameMaxLength}
                onChange={(e) => {
                  setCurrentUser({ ...currentUser, name: e.target.value });
                }}
              />
            </div>
            <div className="col-md-3 col-lg-2 mt-2 mt-md-2">
              <label htmlFor="email" className="form-label fw-bold ">
                {t("EMAIL")}
              </label>
            </div>
            <div className="col-md-9 col-lg-10 mt-0 mt-md-2">
              <input
                type="text"
                className="form-control"
                value={currentUser.email}
                id="email"
                name="email"
                maxLength={settings.auth.emailMaxLength}
                required
                onChange={(e) => {
                  setCurrentUser({ ...currentUser, email: e.target.value });
                }}
              />
            </div>
            <div className="col-12 text-end">
              {loading === "pending" && (
                <button
                  className="w-100 btn btn-info mt-3"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-grow spinner-grow-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="sr-only ps-1">{t("LOADING")}...</span>
                </button>
              )}

              {loading === "idle" && (
                <button type="submit" className="btn btn-primary mt-3">
                  {t("SAVE")}
                </button>
              )}

              <button
                type="button"
                className="ms-2 btn btn-secondary mt-3"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrentUser(user);
                }}
              >
                {t("CANCEL")}
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};
export default ProfileUpdate;
