import { configureStore } from '@reduxjs/toolkit'
import markersReducer from '../slices/mapSlice'
import userReducer from '../slices/userSlice'

export default configureStore({
  reducer: {
    markers: markersReducer,
    user: userReducer,
  },
})
