import { useEffect } from "react";
import {
  setCurrentCoordinates,
} from "../../../slices/mapSlice";
import { useDispatch } from "react-redux";


/**
 * Custom hook for setting coordinates.
 *
 * @param {Function} dispatch - The dispatch function.
 * @param {Object} geolocation - The geolocation object.
 */
const useSetCurrentCoordinatesHook = (
  geolocation
) => {
  const dispatch = useDispatch();
  useEffect(() => {
    console.log("--------------> GEOLOCATION ONLY ONCE");

    // Escuchar los cambios en la posición
    geolocation.on("change", () => {
      console.log("--------------> GEOLOCATION CHANGE");
      const position = geolocation.getPosition();

      const accuracy = geolocation.getAccuracy();
      const heading = geolocation.getHeading();

      console.log("heading", heading);
      console.log("accuracy", accuracy);

      dispatch(
        setCurrentCoordinates({
          latitude: position[1],
          longitude: position[0],
          geolocation: true,
          heading: heading,
          accuracy: accuracy,
        })
      );
    });
  }, [dispatch, geolocation]);


};
export default useSetCurrentCoordinatesHook;
