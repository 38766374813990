import React from "react";
import { useTranslation } from "react-i18next";

const ForgotPasswordFormSuccess = ({
  setForm,
}) => {
  const { t } = useTranslation();

  return (
    <div className="content p-4 rounded-4">
      <div className="text-light fw-bold fs-4 text-center">
        {t("RESTORE_PASSWORD_EMAIL_SENT")}
      </div>
      <div className="separator mt-3"></div>

      <div className="text-light text-large text-center mt-3 cursor-pointer">
        {t("HAVE_ACCOUNT")}{" "}
        <span
          className="text-info back-button"
          onClick={() => setForm("LOGIN")}
        >
          {t("LOGIN")}
        </span>
      </div>
    </div>
  );
};

export default ForgotPasswordFormSuccess;
