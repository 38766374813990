import { settings } from "../Config";

export async function translateText(text, targetLanguage) {


  const query = `${settings.google_translate.protocol}://${settings.google_translate.path}&target=${targetLanguage}&q=${text}`;
  try {
    const response = await fetch(query, {
      method: "POST",
    });
    const data = await response.json();
    return data.data.translations[0].translatedText;
  } catch (error) {
    console.error("Translation error:", error);
    return text;
  }
}
