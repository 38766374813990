import React from "react";
import { useTranslation } from "react-i18next";
import { settings } from "../../Config";

const LoginForm = ({ submitLogin, message, setForm }) => {
 

  const { t, i18n } = useTranslation();

  return (
    <div className="content p-4 rounded-4">
      <form onSubmit={submitLogin} autoComplete="off">
        <input
          type="hidden"
          name="language_code"
          value={i18n.language.substring(0, 2)}
        />
        <label className="w-100 text-light" htmlFor="email">
          {t("EMAIL")}
        </label>
        <input
          type="email"
          required={true}
          className="form-control w-100"
          name="email"
          id="email"
          maxLength={settings.auth.emailMaxLength}
        />
        <label className="w-100 mt-2 text-light" htmlFor="password">
          {t("PASSWORD")}
        </label>
        <input
          type="password"
          required={true}
          className="form-control w-100"
          name="password"
          id="password"
          minLength={settings.auth.passwordMinLength}
          maxLength={settings.auth.passwordMaxLength}
        />

        {message !== "LOADING" && (
          <button type="submit" className="w-100 btn btn-info mt-3">
            {t("LOGIN")}
          </button>
        )}

        {message === "LOADING" && (
          <button className="w-100 btn btn-info mt-3" type="button" disabled>
            <span
              className="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="sr-only ps-1">{t("LOADING")}...</span>
          </button>
        )}
      </form>

      <div
        className="text-light mt-1 forgot-password-button d-inline-block"
        onClick={() => setForm("FORGOTPASSWORD")}
      >
        {t("FORGOT_PASSWORD")}
      </div>
      <div className="separator mt-3"></div>

      <div
        className="text-light text-center mt-3"
        onClick={() => setForm("SIGNUP")}
      >
        {t("NOT_HAVE_ACCOUNT")}{" "}
        <span className="text-info sign-up-button">{t("SIGN_UP")}</span>
      </div>
    </div>
  );
};

export default LoginForm;
