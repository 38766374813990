import React from "react";
import { useTranslation } from "react-i18next";
import { settings } from "../../Config";

const ForgotPasswordForm = ({ submitRestorePassword, message, setForm }) => {
  const { t } = useTranslation();

  return (
    <div className="content p-4 rounded-4">
      <form onSubmit={submitRestorePassword} autoComplete="off">
        <label className="w-100 text-light" htmlFor="email">
          {t("EMAIL")}
        </label>
        <input
          type="email"
          required={true}
          className="form-control w-100"
          name="email"
          id="email"
          maxLength={settings.auth.emailMaxLength}
        />

        {message !== "LOADING" && (
          <button type="submit" className="w-100 btn btn-info mt-3">
            {t("RECOVER_PASSWORD")}
          </button>
        )}

        {message === "LOADING" && (
          <button className="w-100 btn btn-info mt-3" type="button" disabled>
            <span
              className="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="sr-only ps-1">{t("LOADING")}...</span>
          </button>
        )}
      </form>

      <div className="separator mt-3"></div>

      <div className="text-light text-center mt-3 cursor-pointer">
        {t("HAVE_ACCOUNT")}{" "}
        <span
          className="text-info back-button"
          onClick={() => setForm("LOGIN")}
        >
          {t("LOGIN")}
        </span>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
