import React, { useState } from "react";
import BasicNavigationBar from "./BasicNavigationBar";
import BasicOfflineLayer from "./BasicOfflineLayer";

import { useSelector } from "react-redux";
import useBasicTemplateUserHook from "./hooks/useBasicTemplateUserHook";
import useBasicTemplateErrorHook from "./hooks/useBasicTemplateErrorHook";
import useBasicTemplateConnectionHook from "./hooks/useBasicTemplateConnectionHook";

/**
 * Basic template component.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 * @returns {ReactNode} The rendered component.
 */
const BasicTemplate = ({ children }) => {
  const user = useSelector((state) => state.user.data);
  const loading = useSelector((state) => state.user.loading);

  const [connected, setConnected] = useState(true);

  useBasicTemplateUserHook();
  useBasicTemplateErrorHook();
  useBasicTemplateConnectionHook(setConnected);

  return (
    <>
      {loading === "idle" && user && (
        <>
          <BasicNavigationBar />
          <main className="main-container d-flex flex-column flex-grow-1 justify-content-start align-items-center my-0 mx-auto w-100 p-0">
            {children}
          </main>

          {!connected && <BasicOfflineLayer />}
        </>
      )}
      {loading === "pending" && (
        <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
          <div className="text-center">
            <div className="spinner"></div>
          </div>
        </div>
      )}
    </>
  );
};

export default BasicTemplate;
