export const getLocaleDate = (date_str) => {
  let dateObj = new Date(date_str);
  if (!isNaN(dateObj)) {
    let options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    return dateObj.toLocaleString(undefined, options);
  } else {
    return "";
  }
};
export const isImage = (path) => {
  if (
    path.toUpperCase().endsWith(".JPG") ||
    path.toUpperCase().endsWith(".JPEG") ||
    path.toUpperCase().endsWith(".SVG") ||
    path.toUpperCase().endsWith(".BMP") ||
    path.toUpperCase().endsWith(".GIF") ||
    path.toUpperCase().endsWith(".PNG")
  ) {
    return true;
  }
  return false;
};
