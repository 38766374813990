import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./assets/scss/custom.scss";
import React, { useState } from "react";
import Protected from "./components/router/Protected";

import {
  HashRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import SendConfirmEmailPage from "./pages/SendConfirmEmailPage";
import MapPage from "./pages/MapPage";
import MapConfigProvider from "./contexts/MapConfigProvider";
import ConfirmEmailPage from "./pages/ConfirmEmailPage";
import NotFoundPage from "./pages/NotFoundPage";
import RestorePasswordPage from "./pages/RestorePasswordPage";
import ProfilePage from "./pages/ProfilePage";
import HelpPage from "./pages/HelpPage";

function App() {
  console.log("APP");
  const auth = localStorage.getItem("auth");
  var authJson = {};
  if (auth) {
    try {
      authJson = JSON.parse(auth);
    } catch (error) {
      localStorage.removeItem("auth");
    }
  }
  console.log("authJson", authJson);
  const [authObject, setAuthObject] = useState(authJson);

  return (
    <MapConfigProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              authObject.access_token ? (
                <Navigate to="/map" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/login"
            element={
              authObject.access_token ? (
                <Navigate to="/map" />
              ) : (
                <LoginPage setAuthObject={setAuthObject} />
              )
            }
          />
          <Route path="/confirm-email" element={<ConfirmEmailPage />} />
          <Route
            path="/restore-password/:email/:code"
            element={<RestorePasswordPage />}
          />

          <Route
            path="/map"
            element={
              <Protected isSignedIn={authObject.access_token ? true : false}>
                <MapPage />
              </Protected>
            }
          />

          <Route
            path="/send-confirmation-email/:email"
            element={<SendConfirmEmailPage />}
          />

          <Route
            path="/profile"
            element={
              <Protected isSignedIn={authObject.access_token ? true : false}>
                <ProfilePage />
              </Protected>
            }
          />
          <Route
            path="/help"
            element={
              <Protected isSignedIn={authObject.access_token ? true : false}>
                <HelpPage />
              </Protected>
            }
          />

          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </Router>
    </MapConfigProvider>
  );
}

export default App;
