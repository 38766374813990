import { useEffect } from "react";

/**
 * Custom hook for setting coordinates.
 *
 * @param {Object} feature - The feature from the map.
 * @param {Object} content - An object with the html.
 * @param {Object} modalContent - The dom element.
 * @param {function} openArticle - Function to open an article.
 */
const useArticleViewerOpenArticleHook = (
  feature,
  openArticle
) => {
  useEffect(() => {
    openArticle(feature.get("article"));
  }, [feature, openArticle]);
};
export default useArticleViewerOpenArticleHook;
