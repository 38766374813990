import React, { useRef, useMemo, useState } from "react";
import Geolocation from "ol/Geolocation";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentCoordinates } from "../../slices/mapSlice";


import useMapLoadMarkersHook from "./hooks/useMapLoadMarkersHook";
import useMapSetCoordinatesHook from "./hooks/useMapSetCoordinatesHook";
import useMapInitMapHook from "./hooks/useMapInitMapHook";
import useMapSetCurrentCoordinatesHook from "./hooks/useMapSetCurrentCoordinatesHook";
import useMapGetMarkersHook from "./hooks/useMapGetMarkersHook";

import { getIP, updateUserGeolocation } from "../../api/user";

import { getLocationByIP } from  '../../services/IpGeolocationService';


import NoGeolocationMessage from "./NoGeolocationMessage";
import MarkerInfoModal from "./MarkerInfoModal";
import MarkersErrorMessage from "./MarkersErrorMessage";
import MarkersSearchFloatingButton from "./MarkersSearchFloatingButton";
import MapCurrentLocationButton from "./MapCurrentLocationButton";
/**
 * Represents a Map component.
 *
 * @component
 * @returns {JSX.Element} Map component
 */
const Map = () => {
  const dispatch = useDispatch();
  const mapElement = useRef();
  const mapOL = useRef(null);
  const display = useRef("all");
  const currentLocationMarkerRef = useRef(null);
  const followGeolocationRef = useRef(true);

  // Declare state variables
  const [showSearch, setShowSearch] = useState(false);
  const [showNoGeolocationMessage, setShowNoGeolocationMessage] =
    useState(false);
  const [selectedFeature, setSelectedFeature] = useState(null);

  // Declare selector variables
  const currentCoordinates = useSelector(
    (state) => state.markers.currentCoordinates
  );
  const loading = useSelector((state) => state.markers.loading);
  const error = useSelector((state) => state.markers.error);

  // Create a new Geolocation instance
  const geolocation = useMemo(
    () =>
      new Geolocation({
        tracking: true,
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: "EPSG:4326",
      }),
    []
  );

  geolocation.on("error", function () {

    setShowNoGeolocationMessage(true);

    getIP().then((response) => {
      console.log("response: ", response.data);
      getLocationByIP(response.data.data.IP).then((data) => {
        const geo = {
          latitude: data.latitude,
          longitude: data.longitude,
          geolocation: false,
          heading: 0,
          accuracy: 0,
        };
        updateUserGeolocation({ geolocation: JSON.stringify(geo) }).then(
          (response) => {
            console.log("response: ", response);
          }
        );

        dispatch(setCurrentCoordinates(geo));
      });
    });
  });
  // Call 'useSetCurrentCoordinatesHook' hook with 'geolocation' as parameter
  useMapSetCurrentCoordinatesHook(geolocation);
  // Call 'useSetCoordinatesHook' hook with 'mapOL', 'display', 'currentLocationMarkerRef', 'followGeolocationRef' as parameters
  useMapSetCoordinatesHook(
    mapOL,
    display,
    currentLocationMarkerRef,
    followGeolocationRef
  );
  // Call 'useGetMarkersHook' hook
  useMapGetMarkersHook();

  // Call 'useInitMapHook' hook with 'mapOL', 'coordinates', 'display', 'mapElement', 'setShowSearch', 'setSelectedFeature' as parameters
  useMapInitMapHook(
    mapOL,
    display,
    mapElement,
    setShowSearch,
    setSelectedFeature,
    followGeolocationRef
  );

  // Call 'useLoadMarkersHook' hook with 'mapOL', 'setShowSearch', 'data' as parameters
  useMapLoadMarkersHook(mapOL, setShowSearch, currentLocationMarkerRef);

  return (
    <>
      {currentCoordinates && showSearch && selectedFeature === null && (
        <MarkersSearchFloatingButton map={mapOL} loading={loading} />
      )}
      {error && <MarkersErrorMessage />}

      <div className="map-layer flex-grow-1 w-100 position-relative">
        <div id="map" ref={mapElement} className="w-100 h-100"></div>

        {currentCoordinates && selectedFeature === null && (
          <MapCurrentLocationButton
            mapOL={mapOL}
            followGeolocationRef={followGeolocationRef}
          />
        )}
      </div>

      {selectedFeature && (
        <MarkerInfoModal
          map={mapOL}
          feature={selectedFeature}
          setSelectedFeature={setSelectedFeature}
        />
      )}

      {currentCoordinates &&
        currentCoordinates.geolocation === false &&
        showNoGeolocationMessage && (
          <NoGeolocationMessage
            setShowNoGeolocationMessage={setShowNoGeolocationMessage}
          />
        )}
    </>
  );
};

export default Map;
