import React from "react";
import { useTranslation } from "react-i18next";

/**
 * Represents a Map component.
 *
 * @component
 * @returns {JSX.Element} Map component
 */
const NoGeolocationMessage = ({ setShowNoGeolocationMessage }) => {
  const { t } = useTranslation();

  return (
    <div
      className=" mb-0 alert alert-warning alert-dismissible fade show position-fixed bottom-0 start-50 translate-middle-x w-100"
      role="alert"
    >
      {t("NO_GEOLOCATION")}
      <button
        type="button"
        className="btn-close"
        onClick={() => setShowNoGeolocationMessage(false)}
      ></button>
    </div>
  );
};

export default NoGeolocationMessage;
