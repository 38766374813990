import React from "react";

import { useTranslation } from "react-i18next";

const BasicOfflineLayer = () => {
  const { t } = useTranslation();

  return (
    <div className="offline-layer w-100 h-100 position-fixed d-flex flex-column justify-content-center align-items-center">
      <div className="rounded-3 bg-white w-75 h-25 d-flex flex-column justify-content-center align-items-center h4 text-center">{t("NO_INTERNET")}</div>
    </div>
  );
};

export default BasicOfflineLayer;
