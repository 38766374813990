import axios from "axios";

// const devEnv = process.env.NODE_ENV !== "production";

// const { REACT_APP_DEV_API, REACT_APP_PROD_API } = process.env;

// const API = axios.create({
//   baseURL: `${devEnv ? REACT_APP_DEV_API : REACT_APP_PROD_API}`,
// });


const API = axios.create({ baseURL: process.env.REACT_APP_API_URL });



API.interceptors.request.use((req) => {
  if (localStorage.getItem("auth")) {
    req.headers.Authorization = `Bearer ${
      JSON.parse(localStorage.getItem("auth")).access_token
    }`;
  }
  return req;
});

API.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.data && error.response.data.message && 'Unauthenticated.' === error.response.data.message) {
      localStorage.removeItem("auth");
      window.location.reload();
    } else if (error && error.message === 'Network Error') {
      // Handle network error here
      console.log('Network Error:', error.message);
    }
    return Promise.reject(error);
  }
);




export default API;
