import React from "react";
import { useTranslation } from "react-i18next";
import { getLocaleDate } from "../../helpers/Util";

import { useSelector } from "react-redux";
/**
 * Renders a map page with markers for nearby places.
 * @returns {JSX.Element} The map page component.
 */

const ProfileInfo = () => {
  const user = useSelector((state) => state.user.data);

  const { t } = useTranslation();

  return (
    <>
      {user && (
        <div className="row">
          <div className="col-md-2 col-lg-1">
            <strong>{t("NAME")}:</strong>
          </div>
          <div className="col-md-10 col-lg-11">{user.name}</div>
          <div className="col-md-2 col-lg-1 mt-1 mt-md-0">
            <strong>{t("EMAIL")}:</strong>
          </div>
          <div className="col-md-10 col-lg-11">{user.email}</div>
          <div className="col-md-2 col-lg-1 mt-1 mt-md-0">
            <strong>{t("SIGN_UP_DATE")}:</strong>
          </div>
          <div className="col-md-10 col-lg-11">
            {getLocaleDate(user.created_at)}
          </div>
        </div>
      )}
    </>
  );
};
export default ProfileInfo;
