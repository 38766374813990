import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PasswordUpdate from "./PasswordUpdate";
import ProfileInfo from "./ProfileInfo"; 
import ProfileUpdate from "./ProfileUpdate"; 
import ModalMessage from "./ModalMessage";

/**
 * Renders a map page with markers for nearby places.
 * @returns {JSX.Element} The map page component.
 */

const ProfileSettings = () => {
  const [message, setMessage] = useState(null);
  const { t } = useTranslation();

  const closeModal = async (event) => {
    event.preventDefault();

    if (message === "LOADING") {
      return;
    }
    if (message === "CONFIRM_PASSWORD_ERROR") {
      document.getElementById("confirm_password").select();
    } else if (message === "UNAUTHORIZED") {
      document.getElementById("email").select();
    } else if (message === "EMAIL_ALREADY_EXISTS") {
      document.getElementById("email").select();
    }
    setMessage("");
  };

  return (
    <>
      <div className="accordion w-100 accordion-flush" id="accordionProfile">
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button fw-bold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              {t("ACCOUNT_INFORMATION")}
            </button>
          </h2>
          <div
            id="collapseOne"
            className="accordion-collapse collapse show"
            data-bs-parent="#accordionProfile"
          >
            <div className="accordion-body">
              <ProfileInfo />
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed fw-bold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseTwo"
              aria-expanded="false"
              aria-controls="collapseTwo"
            >
              {t("MODIFY_ACCOUNT")}
            </button>
          </h2>
          <div
            id="collapseTwo"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionProfile"
          >
            <div className="accordion-body">
              <ProfileUpdate setMessage={setMessage} />
            </div>
          </div>
        </div>
        <div className="accordion-item">
          <h2 className="accordion-header">
            <button
              className="accordion-button collapsed fw-bold"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseThree"
              aria-expanded="false"
              aria-controls="collapseThree"
            >
              {t("MODIFY_PASSWORD")}
            </button>
          </h2>
          <div
            id="collapseThree"
            className="accordion-collapse collapse"
            data-bs-parent="#accordionProfile"
          >
            <div className="accordion-body">
              <PasswordUpdate setMessage={setMessage} />
            </div>
          </div>
        </div>
      </div>
      {message && <ModalMessage message={message} closeModal={closeModal} />}
    </>
  );
};
export default ProfileSettings;
