import { useEffect } from "react";
import { getMarkers } from "../../../slices/mapSlice";
import { useDispatch, useSelector } from "react-redux";

/**
 * Custom hook for setting coordinates.
 *
 * @param {Object} coordinates - The coordinates object.
 * @param {Function} dispatch - The dispatch function.
 * @param {Object} geolocation - The geolocation object.
 * @param {Object} currentCoordinates - The current coordinates object.
 * @param {Object} mapOL - The mapOL object.
 * @param {string} display - The display setting.
 * @param {Object} currentLocationMarkerRef - The current location marker reference.
 * @param {Object} followGeolocationRef - The follow geolocation reference.
 */
const useGetMarkersHook = () => {
  const dispatch = useDispatch();
  const coordinates = useSelector((state) => state.markers.coordinates);

  useEffect(() => {
    console.log("--------------> ON COORDINATES CHANGE getmarkers");
    // This code block will be executed whenever the 'coordinates' or 'dispatch' dependencies change
    if (coordinates) {
      dispatch(getMarkers(coordinates));
    }
  }, [dispatch, coordinates]);
};
export default useGetMarkersHook;
