import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const AuthModalMessage = ({ message, closeModal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="login-modal d-flex flex-column justify-content-start align-items-center w-100 h-100" onClick={closeModal}>
      {message !== "CONFIRMATION_PENDING" && (
        <p className="error-message text-center w-75 p-3 mt-5 bg-dark text-light">{t(message)}</p>
      )}
      {message === "CONFIRMATION_PENDING" && (
        <p className="error-message text-center w-75 p-3 mt-5 bg-dark text-light">
          {t("CONFIRMATION_PENDING")}{" "}
          <span
            className="text-info"
            onClick={() =>
              navigate(
                `/send-confirmation-email/${
                  document.getElementById("email").value
                }`
              )
            }
          >
            {t("SEND_CONFIRMATION_MAIL")}
          </span>
        </p>
      )}
    </div>
  );
};

export default AuthModalMessage;
