import { addMarkerContent } from "../../../api/markers";
import { translateText } from "../../../services/TranslationService";
import { Style, Icon, Text, Fill, Stroke } from "ol/style";
import { markerIcons, zoomBreakpoints, settings } from "../../../Config";
import GeoJSON from "ol/format/GeoJSON";
import VectorLayer from "ol/layer/Vector";
import { toLonLat } from "ol/proj";
/**
 * Saves the marker content in the local cache and in the API.
 * @param {import("ol/Feature").default} feature - The feature to save the content for.
 * @param {object} i18n - The i18n object.
 * @param {React.MutableRefObject<object[]>} contentCache - The content cache.
 * @param {string} title - The title of the content.
 * @param {string|null} text - The text of the content.
 * @returns {Promise<void>} - A promise that resolves when the content is saved.
 */

export const saveMarkerContent = async (
  feature,
  i18n,
  contentCache,
  title,
  text = null
) => {
  const data = {};
  if (title !== null && title !== "") {
    data.title = title;
  }
  if (text !== null && text !== "") {
    data.text = text;
  }
  storeMarkerContentInCache(
    {
      id: feature.get("id"),
      language_code: i18n.language.substring(0, 2),
      ...data,
    },
    contentCache
  );

  await addMarkerContent(
    feature.get("id"),
    i18n.language.substring(0, 2),
    data
  );
};

/**
 * Stores the marker content in the local cache.
 * @param {object} data - The data to store in the cache.
 * @param {React.MutableRefObject<object[]>} contentCache - The content cache.
 */
export const storeMarkerContentInCache = async (data, contentCache) => {
  const index = contentCache.current.findIndex(
    (item) => item.id === data.id && item.language_code === data.language_code
  );

  if (index !== -1) {
    contentCache.current[index] = {
      ...contentCache.current[index],
      ...data,
    };
  } else {
    contentCache.current.push(data);
  }
};



// Retrieves the marker content from the local cache
export const getMarkerContentFromCache = (feature, contentCache, i18n) => {
  const index = contentCache.current.findIndex(
    (item) =>
      item.id === feature.get("id") &&
      item.language_code === i18n.language.substring(0, 2)
  );

  if (index !== -1) {
    return contentCache.current[index];
  } else {
    return null;
  }
};

export const translateContent = async (page, feature, i18n, contentCache) => {
  const data = {};

  if (page.title) {
    try {
      data.title = await translateText(
        page.title,
        i18n.language.substring(0, 2)
      );

      if (page.extract) {
        data.extract = await translateText(
          page.extract,
          i18n.language.substring(0, 2)
        );

        saveMarkerContent(
          feature,
          i18n,
          contentCache,
          data.title,
          data.extract
        );
      }
    } catch (error) {
      console.error(error);
    }
  }

  return data;
};

// Función para crear un estilo con un icono dinámico
export const createStyle = (feature, display) => {
  if (display === "nothing") {
    return new Style();
  }

  const text = feature.get("label");

  if (text) {
    const maxWidth = 24;
    let lines = [];
    let line = "";

    for (let i = 0; i < text.length; i++) {
      line += text[i];
      if (line.length === maxWidth || i === text.length - 1) {
        lines.push(line);
        line = "";
      }
    }

    const formattedText = lines.join("\n");

    const textStyle = new Text({
      overflow: false,
      offsetX: 20,
      font: `${display === settings.breakpoints.ALL_SMALL ? 12 : 14}px Arial`,
      text: formattedText,
      fill: new Fill({
        color: "#000",
      }),
      stroke: new Stroke({
        color: "#fff",
        width: display === settings.breakpoints.ALL_SMALL ? 2 : 3,
      }),
      textAlign: "start",
    });

    return new Style({
      image: new Icon({
        src: `imgs/marker-icons/${markerIcons[feature.get("marker_type_id")]}`,
        scale: display === settings.breakpoints.ONLY_ICONS_SMALL ? 0.025 : 0.05,
      }),
      text:
        display === settings.breakpoints.ALL_SMALL ||
        display === settings.breakpoints.ALL_BIG
          ? textStyle
          : null,
    });
  } else {
    return new Style({
      image: new Icon({
        src: settings.currentLocationMarker.src,
        scale:
          display === settings.breakpoints.ONLY_ICONS_SMALL
            ? settings.currentLocationMarker.scaleSmall
            : settings.currentLocationMarker.scaleBig,
      }),
    });
  }
};

export const getFeatures = (data) => {

  const features = new GeoJSON().readFeatures(
    {
      type: "FeatureCollection",
      features: data,
    },
    settings.map.features.wktOptions
  );
  return features;
};

/**
 * Processes the zoom level and updates the display and style of the features accordingly.
 * @param {import("ol/Map").default} mapOL - The OpenLayers map instance.
 * @param {string} display - The current display setting.
 * @param {React.MutableRefObject<HTMLElement>} floatingTopButton - The floating top button ref.
 * @param {object} coordinates - The coordinates object.
 */

export const processZoom = (mapOL, display, coordinates) => {
  const zoom = mapOL.current.getView().getZoom();

  const response = { showSearch: false };

  const vectorLayer = mapOL.current
    .getLayers()
    .getArray()
    .find((layer) => layer instanceof VectorLayer);

  let mapFeatures = null;

  if (vectorLayer) {
    const vectorSource = vectorLayer.getSource();
    mapFeatures = vectorSource.getFeatures();

    // If the zoom level is less than 10, hide all features
    if (
      zoom <= zoomBreakpoints.nothing &&
      display.current !== settings.breakpoints.NOTHING
    ) {
      display.current = settings.breakpoints.NOTHING;

      response.showSearch = false;

      mapFeatures.forEach((feature) => {
        feature.setStyle(createStyle(feature, display.current));
      });
      vectorSource.clear();
      vectorSource.addFeatures(mapFeatures);

      return response;
    } else if (zoom > zoomBreakpoints.nothing) {
      if (
        // If the zoom level is between 10 and 11, show only icons

        zoom <= zoomBreakpoints.onlyIconsSmall &&
        display.current !== settings.breakpoints.ONLY_ICONS_SMALL
      ) {
        display.current = settings.breakpoints.ONLY_ICONS_SMALL;

        mapFeatures.forEach((feature) => {
          feature.setStyle(createStyle(feature, display.current));
        });
        vectorSource.clear();
        vectorSource.addFeatures(mapFeatures);
      } else if (
        zoom > zoomBreakpoints.onlyIconsSmall &&
        zoom <= zoomBreakpoints.onlyIconsBig &&
        display.current !== settings.breakpoints.ONLY_ICONS_BIG
      ) {
        // If the zoom level is between 11 and 13, show only icons
        display.current = settings.breakpoints.ONLY_ICONS_BIG;
        mapFeatures.forEach((feature) => {
          feature.setStyle(createStyle(feature, display.current));
        });
        vectorSource.clear();
        vectorSource.addFeatures(mapFeatures);
      } else if (
        zoom > zoomBreakpoints.onlyIconsBig &&
        zoom <= zoomBreakpoints.allSmall &&
        display.current !== settings.breakpoints.ALL_SMALL
      ) {
        // If the zoom level is between 13 and 17, show all features
        display.current = settings.breakpoints.ALL_SMALL;
        mapFeatures.forEach((feature) => {
          feature.setStyle(createStyle(feature, display.current));
        });
        vectorSource.clear();
        vectorSource.addFeatures(mapFeatures);
      } else if (
        zoom > zoomBreakpoints.allSmall &&
        display.current !== settings.breakpoints.ALL_BIG
      ) {
        // If the zoom level is greater than 17, show all features
        display.current = settings.breakpoints.ALL_BIG;
        mapFeatures.forEach((feature) => {
          feature.setStyle(createStyle(feature, display.current));
        });
        vectorSource.clear();
        vectorSource.addFeatures(mapFeatures);
      }

      const center = mapOL.current.getView().getCenter();
      const lonLat = toLonLat(center);
      if (
        lonLat[1] !== coordinates.latitude &&
        lonLat[0] !== coordinates.longitude
      ) {
        response.showSearch = true;
      }
    }
  }

  return response;
};
