import React, { useRef } from "react";

import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useBasicNavigationBarHook from "./hooks/useBasicNavigationBarHook";

const BasicNavigationBar = () => {
  let location = useLocation();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.data);
  const navbarCollapse = useRef(null);

  const logout = () => {
    localStorage.removeItem("auth");
    window.location.href = "/";
  };

  useBasicNavigationBarHook(navbarCollapse);

  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-dark">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/map">
          <img src="/imgs/logo.svg" alt="Where" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse"
          id="navbarCollapse"
          ref={navbarCollapse}
        >
          <ul className="navbar-nav ms-auto mt-1 mb-2 mb-md-0">
            <li className="nav-item">
              <Link
                className={`nav-link text-uppercase  ${
                  location.pathname === "/map" ? "active" : ""
                }`}
                to="/map"
              >
                {t("MAP")}
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link text-uppercase  ${
                  location.pathname === "/help" ? "active" : ""
                }`}
                to="/help"
              >
                {t("HELP")}
              </Link>
            </li>

            <li className="nav-item d-block d-md-none text-uppercase">
              <Link
                className={`nav-link  ${
                  location.pathname === "/profile" ? "active" : ""
                }`}
                to="/profile"
              >
                {user.name}
              </Link>
            </li>
            <li className="border-top border-secondary mt-3 pt-2 nav-item d-block d-md-none text-uppercase">
              <span className="nav-link" onClick={logout}>
                {t("LOGOUT")}
              </span>
            </li>

            <li className="nav-item dropdown d-none d-md-block">
              <a
                className="nav-link dropdown-toggle text-uppercase"
                href="#nav"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {user.name}
              </a>
              <ul
                className="dropdown-menu dropdown-menu-end bg-dark"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <Link className="dropdown-item text-uppercase" to="/profile">
                    {t("MY_ACCOUNT")}
                  </Link>
                </li>
                <li>
                  <hr className="dropdown-divider border-light" />
                </li>
                <li>
                  <span className="dropdown-item text-uppercase" onClick={logout}>
                    {t("LOGOUT")}
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default BasicNavigationBar;
