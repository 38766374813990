import React, { useRef, useState, useCallback } from "react";

import { getArticleFromWikipedia } from "../../api/markers";
import logo from "../../assets/imgs/logo-min.png";
import { useTranslation } from "react-i18next";
import useArticleViewerContentHook from "./hooks/useArticleViewerContentHook";
import useArticleViewerOpenArticleHook from "./hooks/useArticleViewerOpenArticleHook";

/**
 * Renders a map page with markers for nearby places.
 * @returns {JSX.Element} The map page component.
 */

const ArticleViewer = ({ feature, setShowArticle }) => {
  const modal = useRef(null);
  const modalContent = useRef(null);
  const [content, setContent] = useState(null);
  const [error, setError] = useState(false);
  const { t } = useTranslation();

  const openArticle = useCallback(
    async (url) => {
      setContent(null);
      const decodedUrl = decodeURIComponent(url);
      const title = decodedUrl.substring(decodedUrl.lastIndexOf("/") + 1);

      try {
        const content = await getArticleFromWikipedia(
          title,
          feature.get("article_language_code")
        );
        if (content.data && content.data.html) {
          setContent(content.data);
        } else {
          setError(true);
        }
      } catch (error) {
        setError(true);
      }
    },
    [feature]
  );

  useArticleViewerOpenArticleHook(feature, openArticle);

  // Call the custom hook
  useArticleViewerContentHook(feature, content, modalContent, openArticle);

  return (
    <>
      <div
        ref={modal}
        className="article-modal position-fixed top-0 start-0 w-100 h-100 bg-light"
      >
        {error && (
          <div className="alert alert-danger" role="alert">
            {t("ERROR_LOADING_ARTICLE")}
          </div>
        )}
        {content && (
          <>
            <div
              ref={modalContent}
              className="content ps-3 pe-3 pb-3"
              dangerouslySetInnerHTML={{
                __html: `<div class="w-100 mt-2 text-dark h6 fw-bold border-bottom border-secondary py-1 text-uppercase">${content.title}</div>${content.html}`,
              }}
            />
          </>
        )}
        {content === null && !error && (
          <div className="content-loading flex-grow-1 overflow-hidden p-3 d-flex flex-column justify-content-center align-items-center justify-content-center">
            <div className="animation flex-grow-1 w-100 d-flex flex-column justify-content-center align-items-center">
              <span className="spinner"></span>
            </div>
          </div>
        )}
        <div className="article-bar bg-dark w-100 position-fixed top-0 start-0 d-flex flex-row justify-content-between align-items-center">
          <div className="logo w-auto" onClick={() => setShowArticle(false)}>
            <img src={logo} alt="Where" />
          </div>
          <div
            className="title d-flex overflow-hidden text-light pe-2"
            onClick={(event) => openArticle(feature.get("article"))}
          >
            <span className="d-inline-block w-100 px-2">
              {feature && feature.get("article_title")
                ? feature.get("article_title")
                : ""}
            </span>
          </div>
          <div className="close-button" onClick={() => setShowArticle(false)}>
            <span>x</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ArticleViewer;
