import { settings } from "../Config";

/**
 * Fetches extract from Wikipedia for a given feature.
 * @param {import("ol/Feature").default} feature - The feature to fetch Wikipedia content for.
 * @returns {Promise<object>} - A promise that resolves with the fetched Wikipedia content.
 */
export const fetchWikipediaExtract = async (feature) => {
    const url = feature.get("article");
    const lastSegment = url.substring(url.lastIndexOf("/") + 1);
    const query = `${settings.wikipedia.protocol}://${feature.get(
      "article_language_code"
    )}.${settings.wikipedia.path}?${
      settings.wikipedia.extract_url_params
    }${lastSegment}`;
  
    return await fetch(query).then((response) => response.json());
  };