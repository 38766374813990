import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { updateUserPassword } from "../../api/user";
import { settings } from "../../Config";

/**
 * Renders a map page with markers for nearby places.
 * @returns {JSX.Element} The map page component.
 */

const PasswordUpdate = ({ setMessage }) => {
  const { t } = useTranslation();
  const [processing, setProcessing] = useState(false);

  const updatePassword = async (event) => {
    event.preventDefault();
    setProcessing(true);
    var data = new FormData(event.target);
    if (data.get("password") !== data.get("confirm_password")) {
      setMessage("CONFIRM_PASSWORD_ERROR");
    } else {
      try {
        await updateUserPassword(data);
      } catch (err) {
        if (err.response.data.error === "VALIDATION_ERROR") {
          setMessage("VALIDATION_ERROR");
        } else {
          setMessage("UNDEFINED_ERROR");
        }
      }
    }
    setProcessing(false);
  };

  return (
    <form autoComplete="off" onSubmit={updatePassword}>
      <div className="row">
        <div className="col-md-3 col-lg-2">
          <label htmlFor="password" className="form-label fw-bold">
            {t("PASSWORD")}
          </label>
        </div>
        <div className="col-md-9 col-lg-10">
          <input
            type="password"
            className="form-control"
            id="password"
            name="password"
            required
            minLength={settings.auth.passwordMinLength}
            maxLength={settings.auth.passwordMaxLength}
          />
        </div>
        <div className="col-md-3 col-lg-2 mt-2 mt-md-2">
          <label htmlFor="confirm_password" className="form-label fw-bold ">
            {t("CONFIRM_PASSWORD")}
          </label>
        </div>
        <div className="col-md-9 col-lg-10 mt-0 mt-md-2">
          <input
            type="password"
            className="form-control"
            id="confirm_password"
            name="confirm_password"
            required
            minLength={settings.auth.passwordMinLength}
            maxLength={settings.auth.passwordMaxLength}
          />
        </div>
        <div className="col-12 text-end">
          {!processing && (
            <button type="submit" className="btn btn-primary mt-3">
              {t("SAVE")}
            </button>
          )}

          {processing && (
            <button className="btn btn-primary mt-3" type="button" disabled>
              <span
                className="spinner-grow spinner-grow-sm"
                role="status"
                aria-hidden="true"
              ></span>
              <span className="sr-only ps-1">{t("LOADING")}...</span>
            </button>
          )}

          <button
            type="button"
            className={`ms-2 btn btn-secondary mt-3 ${processing ? "disabled" : ""}`}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {t("CANCEL")}
          </button>
        </div>
      </div>
    </form>
  );
};
export default PasswordUpdate;
