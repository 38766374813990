import { useEffect } from "react";

/**
 * Custom hook for getting user data and check auth.
 *
 */
const useBasicNavigationBarHook = (navbarCollapse) => {


  useEffect(() => {
    document.addEventListener("click", (event) => {
      if (
        navbarCollapse.current &&
        !navbarCollapse.current.contains(event.target)
      ) {
        navbarCollapse.current.classList.remove("show");
      }
    });
    return () => {
      document.removeEventListener("click", () => {});
    };
  });
};
export default useBasicNavigationBarHook;
