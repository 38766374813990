import { useContext, useEffect } from "react";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import MapWrapper from "../../../mapol/MapWrapper";
import { fromLonLat } from "ol/proj";
import { settings, zoomBreakpoints } from "../../../Config";
import { useDispatch, useSelector } from "react-redux";

import { createStyle, processZoom } from "../utils/Util";
import { View } from "ol";
import MapConfigContext from "../../../contexts/MapConfigContext";

const useInitMapHook = (
  mapOL,
  display,
  mapElement,
  setShowSearch,
  setSelectedFeature,
  followGeolocationRef
) => {
  const mapConfig = useContext(MapConfigContext);
  const dispatch = useDispatch();
  const coordinates = useSelector((state) => state.markers.coordinates);
  useEffect(() => {
    // This code block will be executed whenever the 'mapOL' dependency changes
    console.log("init map hook");
    // Check if 'mapOL' is  null and 'coordinates' is not null
    if (mapOL.current === null && coordinates) {
      console.log("--------------> MAP ONLY ONCE");

      // Create a new VectorSource with the features
      const vectorSource = new VectorSource({
        features: [],
      });

      // Create a new VectorLayer with the VectorSource and a style function
      const vectorLayer = new VectorLayer({
        source: vectorSource,
        style: (feature) => createStyle(feature, display.current),
      });

      // Create a new MapWrapper with the target set to 'mapElement.current' and the layers set to an array containing the VectorLayer
      mapOL.current = new MapWrapper({
        target: mapElement.current,
        controls: [],
        layers: [vectorLayer],
        view: new View({
          center: [0, 0],
          zoom: 2,
        }),
      });



      // Add the 'processZoom' function as a listener to the 'change:resolution' event
      mapOL.current.getView().on("change:resolution", () => {
        const data = processZoom(mapOL, display, coordinates);
        setShowSearch(data.showSearch);
        mapConfig.zoom = mapOL.current.getView().getZoom();
      });
      // Add the pointerdrag event listener to the map
      mapOL.current.on("pointerdrag", (event) => {
        // This function will be executed whenever a 'pointerdrag' event is fired on the 'mapOL.current' object
        followGeolocationRef.current = false;
      });
      // Add the click event listener to the map
      mapOL.current.on("click", (event) => {
        event.stopPropagation();
        // This function will be executed whenever a 'click' event is fired on the 'mapOL.current' object

        // Get the feature at the pixel position
        const feature = mapOL.current.forEachFeatureAtPixel(
          event.pixel,
          (feature) => feature
        );
        // Check if a feature was found
        if (feature && feature.get("id")) {
          // Show the popup
          setSelectedFeature(feature);
        } else {
          // Hide the popup
          setSelectedFeature(null);
        }
      });

      // set map center location
      if (mapConfig.center) {
        // If center coordinates are stored in local storage, use them
        mapOL.current.getView().setCenter(mapConfig.center);
      } else {
        // If center coordinates are not stored in local storage, use the coordinates from the 'coordinates' variable
        const center = fromLonLat([
          coordinates.longitude,
          coordinates.latitude,
        ]);
        mapOL.current.getView().setCenter(center);
      }
      //set map zoom
      mapOL.current
        .getView()
        .setZoom(mapConfig.zoom ? mapConfig.zoom : settings.zoomDefault);

      mapOL.current.getView().on("change:center", function () {
        // Get the map center coordinates
        const center = mapOL.current.getView().getCenter();

        // Stores map center coordinates in local storage
        mapConfig.current.center = center;

        // Sets the visibility of the 'floatingTopButton' ref
        const zoom = mapOL.current.getView().getZoom();

        setShowSearch(zoom > zoomBreakpoints.nothing);
      });
    }
    return () => {};
  }, [
    mapOL,
    coordinates,
    display,
    mapElement,
    setShowSearch,
    setSelectedFeature,
    dispatch,
    mapConfig,
    followGeolocationRef
  ]);
};
export default useInitMapHook;
