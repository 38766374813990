import { useEffect } from "react";
import { useSelector } from "react-redux";

/**
 * Custom hook for getting user data and check auth.
 *
 */
const useBasicTemplateErrorHook = () => {

  const error = useSelector((state) => state.user.error);

  useEffect(() => {
    console.log("BasicTemplate error", error);

    if (error) {
      localStorage.removeItem("auth");
      window.location.reload();
    }
  }, [error]);
};
export default useBasicTemplateErrorHook;
