import React, { useState } from "react";
import { sendConfirmationMail } from "../api/auth";
import { useTranslation } from "react-i18next";

import { Link, useNavigate, useParams } from "react-router-dom";
const SendConfirmEmailPage = () => {
  const navigate = useNavigate();

  const { email } = useParams();
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState(false);

  const { t } = useTranslation();
  const submit = async (event) => {
    event.preventDefault();
    var data = new FormData(event.target);

    try {
      await sendConfirmationMail(data);
      setMessage("");
      setStatus(true);
    } catch (err) {


      if (err && err.response && err.response.data) {
        if (err.response.data.error === "EMAIL_CONFIRMED") {
          setMessage("EMAIL_CONFIRMED");
        } else if (err.response.data.error === "VALIDATION_ERROR")
          setMessage("VALIDATION_ERROR");
      } else {
        setMessage("UNDEFINED_ERROR");
      }
    }
  };
  const closeModal = async (event) => {
    event.preventDefault();

    if (message === "LOADING") {
      return;
    } else {
      navigate("/");
    }
  };
  return (
    <div className="basic-container d-flex align-items-center justify-content-center flex-column flex-grow-1">
      <img className="logo" src="/imgs/logo.svg" alt="Where" />

      <div className="content p-4 rounded-4">
        {!status && (
          <form onSubmit={submit} autoComplete="off">
            <label className="w-100 text-light" htmlFor="email">
              {t("EMAIL")}
            </label>
            <input
              type="email"
              required={true}
              className="form-control w-100"
              name="email"
              id="email"
              value={email}
            />

            {message !== "LOADING" && (
              <button type="submit" className="w-100 btn btn-info mt-3">
                {t("SEND_CONFIRMATION_MAIL")}
              </button>
            )}

            {message === "LOADING" && (
              <button
                className="w-100 btn btn-info mt-3"
                type="button"
                disabled
              >
                <span
                  className="spinner-grow spinner-grow-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span className="sr-only ps-1">{t("LOADING")}...</span>
              </button>
            )}
          </form>
        )}
        {status && (
          <>
            <p className="text-light fs-4">{t("CONFIRMATION_MAIL_SENT")}</p>
            <Link className="w-100 btn btn-info btn-lg mt-3" to="/">
              {t("LOGIN")}
            </Link>
          </>
        )}
      </div>
      {message && (
        <div className="login-modal d-flex flex-column justify-content-start align-items-center w-100 h-100" onClick={closeModal}>
          <p className="error-message text-center w-75 p-3 mt-5 bg-dark text-light">{t(message)}</p>
        </div>
      )}
    </div>
  );
};

export default SendConfirmEmailPage;
