import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
/**
 * Renders a map page with markers for nearby places.
 * @returns {JSX.Element} The map page component.
 */

const ConfirmEmailPage = () => {
  // pull refs

  const { t } = useTranslation();
  return (
    <div
      className="basic-container d-flex align-items-center justify-content-center flex-column flex-grow-1"
    >
      <img className="logo" alt="Where" src="/imgs/logo.svg" />

      <div className="content p-4 rounded-4">
        <p className="text-light fs-4">{t("EMAIL_CONFIRMED")}</p>
        <p className="text-center">
          <Link className="btn btn-info btn-lg mt-3 mb-1" to="/login">
            {t("LOGIN")}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default ConfirmEmailPage;
