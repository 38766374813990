import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/**
 * Custom hook for getting user data and check auth.
 *
 */
const useLoginPageHook = (setAuthObject) => {

  const navigate = useNavigate();

  const user = useSelector((state) => state.user.data);

  useEffect(() => {
    console.log("user", user);

    if (user !== null) {
      const auth = localStorage.getItem("auth");
      const data = JSON.parse(auth);
      setAuthObject(data);

      navigate("/map");
    }
  }, [user, navigate, setAuthObject]);
};
export default useLoginPageHook;
