export const markerIcons = {
  ancientCity: "ancientCity.svg",
  ancientRomanStructure: "ancientRomanStructure.svg",
  archaeologicalSite: "archaeologicalSite.svg",
  architecturalElement: "architecturalElement.svg",
  artsVenue: "artsVenue.svg",
  bridge: "bridge.svg",
  builtStructure: "builtStructure.svg",
  castle: "castle.svg",
  cave: "cave.svg",
  civilEngineeringConstruction: "engineering.svg",
  commercialBuilding: "store.svg",
  culturalPropertyOfSpain: "default.svg",
  energyGeneratingStation: "energyGeneratingStation.svg",
  formerBuildingOrStructure: "residentialBuilding.svg",
  fortifiedLine: "tank.svg",
  funeraryStructure: "cemetery.svg",
  geographicalFeature: "geographicalFeature.svg",
  geologicalSite: "rock.svg",
  greenHouse: "greenHouse.svg",
  harbour: "anchor.svg",
  hauntedHouse: "house.svg",
  heritageMonument: "heritageMonument.svg",
  heritagePark: "park.svg",
  historicalPark: "park.svg",
  historicBuilding: "residentialBuilding.svg",
  historicSite: "historicSite.svg",
  holyCity: "holyCity.svg",
  house: "house.svg",
  inundation: "water.svg",
  jetty: "default.svg",
  libraryBuilding: "book.svg",
  mansion: "mansion.svg",
  megalith: "ruins.svg",
  militaryBuilding: "tank.svg",
  monument: "heritageMonument.svg",
  naturalHeritage: "naturalHeritage.svg",
  offshoreConstruction: "anchor.svg",
  palace: "palace.svg",
  pointOfInterest: "pointOfInterest.svg",
  prisonCamp: "default.svg",
  publicBuilding: "publicBuilding.svg",
  pyramid: "pyramid.svg",
  residentialBuilding: "residentialBuilding.svg",
  ruins: "ruins.svg",
  sculpture: "sculpture.svg",
  square: "square.svg",
  steamBath: "default.svg",
  subterranea: "subterranea.svg",
  temple: "temple.svg",
  tower: "tower.svg",
  tunnel: "subterranea.svg",
  urbanArt: "artsVenue.svg",
};

export const zoomBreakpoints = {
  nothing: 10,
  onlyIconsSmall: 11,
  onlyIconsBig: 13,
  allSmall: 17,
  allBig: 18,
};
export const settings = {
  zoomDefault: 16,
  breakpoints: {
    NOTHING: "nothing",
    ONLY_ICONS_SMALL: "onlyIconsSmall",
    ONLY_ICONS_BIG: "onlyIconsBig",
    ALL_SMALL: "allSmall",
    ALL_BIG: "allBig",
  },
  currentLocationMarker: {
    src: "/imgs/icons/currentLocation.svg",
    scaleBig: 0.05,
    scaleSmall: 0.03,
    rotation: 0,
  },
  auth: {
    passwordMinLength: 6,
    passwordMaxLength: 20,
    emailMaxLength: 50,
    nameMinLength: 4,
    nameMaxLength: 15,
  },
  wikipedia: {
    path: "wikipedia.org/w/api.php",
    protocol: "https",
    extract_url_params:
      "origin=*&format=json&action=query&prop=extracts&exintro&explaintext&redirects=1&titles=",
    article_url_params: "action=parse&format=json&formatversion=2&page=",
  },
  ipgeolocation: {
    path: `api.ipgeolocation.io/ipgeo?apiKey=${process.env.REACT_APP_IPGEOLOCATION_API_KEY}&ip=`,
    protocol: "https",
  },
  google_translate: {
    path: `translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
    protocol: "https",
  },
  map: {
    features: {
      wktOptions: {
        dataProjection: "EPSG:4326",
        featureProjection: "EPSG:3857",
      },
    },
  },
};
