import React from "react";

import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { setError } from "../../slices/mapSlice";

/**
 * Renders a map page with markers for nearby places.
 * @returns {JSX.Element} The map page component.
 */

const MarkersErrorMessage = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();


  return (
    <div className="marker-error position-absolute bg-light p-3 text-dark top-0 start-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center" onClick={()=>dispatch(setError(null))}>
      <div className="text-danger error-message w-75 p-3 mt-5 bg-dark text-light">
        {t("MARKERS_SEARCH_ERROR")}
      </div>
    </div>
  );
};

export default MarkersErrorMessage;
