import React, { useRef } from "react";
import MapConfigContext from "./MapConfigContext";

function MapConfigProvider({ children }) {
  const mapConfig = useRef({ center: null, zoom: null });

  return (
    <MapConfigContext.Provider value={mapConfig}>
      {children}
    </MapConfigContext.Provider>
  );
}

export default MapConfigProvider;
