import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";

class MapWrapper extends Map {
  constructor(options) {
    super({
      target: options.target,
      controls: options.controls,
      layers: [
        
        new TileLayer({
          source: new OSM({
            attributions: [],
          }),
        }),
        ...(options.layers || []),
      ],
      view: options.view,
    });
  }
}

export default MapWrapper;