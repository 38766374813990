import { useEffect } from "react";
import { useTranslation } from "react-i18next";

/**
 * Custom hook for getting user data and check auth.
 *
 */
const useHelpPageHook = (setLoadedHTML) => {
  const { i18n } = useTranslation();
  //i18n.language.substring(0, 2)

  useEffect(() => {
      fetch(`/help/index_${i18n.language.substring(0, 2)}.html`)
        .then((response) => response.text())
        .then((html) => {
          // Do something with the HTML code
          setLoadedHTML(html);
        })
        .catch((error) => {
          console.error("Error fetching HTML:", error);
        });
  }, [i18n.language, setLoadedHTML]);
};
export default useHelpPageHook;
