import { useEffect } from "react";
import { isImage } from "../../../helpers/Util";

/**
 * Custom hook for setting coordinates.
 *
 * @param {Object} feature - The feature from the map.
 * @param {Object} content - An object with the html.
 * @param {Object} modalContent - The dom element.
 * @param {function} openArticle - Function to open an article.
 */
const useArticleViewerContentHook = (feature, content, modalContent, openArticle) => {

  useEffect(() => {
    if (content && modalContent.current) {
      modalContent.scrollTop = 0;
      const links = modalContent.current.getElementsByTagName("a");
      for (let i = 0; i < links.length; i++) {
        links[i].addEventListener("click", (event) => {
          // Handle link click event here
          event.preventDefault();
          event.stopPropagation();
          var href = links[i].getAttribute("href");
          // Do something with the href value
          console.log("Link clicked:", href);

          if (href) {
            if (!isImage(href)) {
              if (
                href.startsWith(
                  `//${feature.get(
                    "article_language_code"
                  )}.wikipedia.org/wiki/`
                ) ||
                href.startsWith(`/wiki/`)
              ) {
                if (href.startsWith(`/wiki/`)) {
                  const title = href.replace("/wiki/", "");
                  href = `//${feature.get(
                    "article_language_code"
                  )}.wikipedia.org/wiki/${title}`;
                }

                openArticle(href);
              } else {
                window.open(href, "_blank");
              }
            }
          }
        });
      }

      // Obtén todas las imágenes en el documento
      var images = modalContent.current.getElementsByTagName("img");

      // Para cada imagen...
      for (var i = 0; i < images.length; i++) {
        // Agrega un controlador de eventos onerror
        images[i].onerror = function () {
          // Si la carga de la imagen falla, elimina la imagen
          this.parentNode.removeChild(this);
        };
      }
    }
  }, [content, feature, openArticle, modalContent]);
};
export default useArticleViewerContentHook;
